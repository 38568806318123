import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import {ressources} from "../../util/Ressources.js"
import DetailItemsList from "../../details/DetailItemsList.js"

export default class InfoHandler extends DetailsHandlerDef {
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Tinglyst",
      targets: [{source: 'tingbogen', typeId: ['servitut', 'haeftelse']}],
      more: true
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
  }
  
  getbuttonText(result) {
    if (result.typeId === "servitut")
      return "Servitut"
    if (result.typeId === "haeftelse")
      return "Hæftelse"
    return this.buttonText
  }
  
  async myHandler(result) {
    let items = []
    if (result.typeId === "servitut") {
      let servitutInfo = result.data
      items.push({
        type: "labelvalue",
        value: servitutInfo.servitutnavn
      })
      if (servitutInfo.DokumentPdf) {
        let ressource = result.searcher.createRessource(servitutInfo.DokumentPdf)
        let ressourceId = ressources.putRessource(ressource)
        items.push({
          ressourceId,
          linkTitle: "Hent dokument",
          type: "link"
        })
      }
      items.push({
        type: "labelvalue",
        label: "Tinglyst",
        value: servitutInfo.tinglysningsDato,
        valueformat: "iso-date"
      })
      items.push({
        type: "labelvalue",
        label: "Har betydning for ejendommensværdi",
        value: servitutInfo.ServitutHarBetydningForEjendommensVaerdiIndikator
      })
      items.push({
        type: "labelvalue",
        label: "Kan tinglyses med prioritet forud for gæld og servitutter",
        value: servitutInfo.ServitutKanTinglysesMedPrioritetForudForGaeldOgServitutterIndikator
      })
      items.push({
        type: "labelvalue",
        label: "Kan tinglyses uden ejers tiltraedelse",
        value: servitutInfo.ServitutKanTinglysesUdenEjersTiltraedelseIndikator
      })
    } else if (result.typeId === "haeftelse") {
      let haeftelseInfo = result.data

      let haeftelsesDetailItemsList = new DetailItemsList({
        header: "Hæftelse"
      })
      if (haeftelseInfo.DokumentPdf) {
        let ressource = result.searcher.createRessource(haeftelseInfo.DokumentPdf)
        let ressourceId = ressources.putRessource(ressource)
        haeftelsesDetailItemsList.append({
          ressourceId,
          linkTitle: "Hent dokument",
          type: "link"
        })
      }
      haeftelsesDetailItemsList.append({
        type: "labelvalue",
        label: "Prioritet Nummer",
        value: haeftelseInfo.PrioritetNummer
      })
      haeftelsesDetailItemsList.append({
        type: "labelvalue",
        label: "Beløb",
        value: haeftelseInfo.BeloebVaerdi,
        valueformat: "currencyamount",
        valueunit: haeftelseInfo.ValutaKode
      })
      if (haeftelseInfo.HaeftelseLaantypeKode) {
        haeftelsesDetailItemsList.append({
          type: "labelvalue",
          label: "Låntype",
          value: haeftelseInfo.HaeftelseLaantypeKode
        })
      }
      if (haeftelseInfo.HaeftelseType) {
        haeftelsesDetailItemsList.append({
          type: "labelvalue",
          label: "Type",
          value: haeftelseInfo.HaeftelseType
        })
      }
      if (haeftelseInfo.HaeftelsePantebrevFormularLovpligtigKode) {
        haeftelsesDetailItemsList.append({
          type: "labelvalue",
          label: "Pantebrevsformularkode",
          value: haeftelseInfo.HaeftelsePantebrevFormularLovpligtigKode
        })
      }
      items.push(haeftelsesDetailItemsList.asItem())

      let tinglysningsDetailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Tinglysning"
      })
      tinglysningsDetailItemsList.append({
        type: "labelvalue",
        label: "Tinglyst",
        value: haeftelseInfo.TinglysningsDato.split("T")[0],
        valueformat: "iso-date"
      })
      if (haeftelseInfo.TinglysningAfgiftBetalt) {
        tinglysningsDetailItemsList.append({
          type: "labelvalue",
          label: "Afgift",
          value: haeftelseInfo.TinglysningAfgiftBetalt,
          valueformat: "currencyamount",
          valueunit: haeftelseInfo.ValutaKode
        })
      }
      items.push(tinglysningsDetailItemsList.asItem())

      //Rente
      let renteDetailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Forrentning"
      })
      renteDetailItemsList.append({
        type: "labelvalue",
        label: "Rente",
        value: haeftelseInfo.rente ? haeftelseInfo.rente : "-"
      })
      renteDetailItemsList.append({
        type: "labelvalue",
        label: "Type",
        value: haeftelseInfo.farstvariabel ? haeftelseInfo.farstvariabel : "-"
      })
      renteDetailItemsList.append({
        type: "labelvalue",
        label: "Rentetekst",
        value: haeftelseInfo.rentetext ? haeftelseInfo.rentetext : "-"
      })
      items.push(renteDetailItemsList.asItem())
      
      //Kreditorer
      if (haeftelseInfo.kreditorarray && haeftelseInfo.kreditorarray.length > 0) {
        let kreditorerDetailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: haeftelseInfo.kreditorarray.length === 1 ? "Kreditor" : "Kreditorer"
        })
        for (let kreditor of haeftelseInfo.kreditorarray) {
          kreditorerDetailItemsList.append({
            type: "labelvalue",
            value: kreditor.navn
          })
        }
        items.push(kreditorerDetailItemsList.asItem())
      }

      //Debitorer
      if (haeftelseInfo.debitorarray && haeftelseInfo.debitorarray.length > 0) {
        let debitorerDetailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: haeftelseInfo.debitorarray.length === 1 ? "Debitor" : "Debitorer"
        })
        for (let debitor of haeftelseInfo.debitorarray) {
          debitorerDetailItemsList.append({
            type: "labelvalue",
            value: debitor.navn
          })
        }
        items.push(debitorerDetailItemsList.asItem())
      }

    }
    return items
  }

}