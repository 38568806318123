import QueryResult from './QueryResult.js'

export default class DataFetcher {
  constructor(completeCallback) {
    this.completeCallback = completeCallback ? completeCallback : () => {
    }
    this._isActive = true
  }

  async fetch(queries) {
    let allPromises = []
    let finalQueryResult = new QueryResult(null)
    for (let query of queries) {
      let promises = await query.searcher.fetchData2(query)
      for (let promise of promises) {
        promise.then(
          (queryResult) => {
            if (this._isActive) {
              this.completeCallback(queryResult.getAllResults(), false)
              finalQueryResult.add(queryResult)
            }
          },
          (error) => {
            throw error
          }
        ).catch(() => {
        })
      }
      allPromises.push(...promises)
    }
    if (this._isActive) {
      await Promise.allSettled(allPromises)
      if (this._isActive) {
        this.completeCallback(finalQueryResult, true)
      }
    }
    this.clean()
    return finalQueryResult
  }

  cancel() {
    this.clean()
  }

  clean() {
    if (this._isActive) {
      this._isActive = false
      this.completeCallback([], true)
    }
  }
}