import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import HusnummerAdresseTilBfeResolver from "./HusnummerAdresseTilBfeResolver.js"


export default class EjendomForJordstykkeDawa extends DetailsHandlerDef {
  constructor(options={}) {

    if(!options.searcher)
      throw "EjendomsResultatForHusnummerProvider must be created with options.searcher"

    let defaultOptions = {
      buttonText: "Ejendom"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (
        (result.source === "Dawa" && result.typeId === "adresse") ||
        (result.source === "Dawa" && result.typeId === "enhedsadresse") ||
        (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre")
      )
    }

    this.searcher = options.searcher
    this.fetcher = options.searcher.fetcher

    this.medtagAdresser = false
    if (options.medtagadresser == true)
      this.medtagAdresser = true
  }

  getbuttonText(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Dawa" && typeId === "adresse")
      return "Ejendom på husnummeret"
    else if (source === "Kortforsyningen" && typeId === "matrikelnumre")
      return "Samlet fast ejendom"
    else if (source === "Dawa" && typeId === "enhedsadresse")
      return "Ejerlejlighed"
  }

  async myHandler(result) {
    let items = []
    let source = result.source
    let typeId = result.typeId
    let bfeNummer
    if (source === "Dawa" && typeId === "adresse") {
      let husnummerTilBfeResolver = new HusnummerAdresseTilBfeResolver({fetcher: this.fetcher, medtagadresser: this.medtagAdresser})
      let {sfe, bfg, ejl} = await husnummerTilBfeResolver.resolveHusnummer(result.id)
      let sfeHeader = "Samlet fast ejendom"
      if (bfg.length > 0) {
        bfeNummer = bfg[0]
        items.push(await this.getDetailItemForBfeNummer(bfeNummer, "Bygning på fremmed grund"))
        sfeHeader = "Ligger på samlet fast ejendom"
      }
      if (sfe.length > 0) {
        bfeNummer = sfe[0]
        items.push(await this.getDetailItemForBfeNummer(bfeNummer, sfeHeader))
      }
      if (ejl.length > 0)
        items.push(await this.getEjlResultsItem(ejl))
    } else if (source === "Kortforsyningen" && typeId === "matrikelnumre") {
      let ejerlavskode = result.data.elavskode
      let matrikelnr = result.data.matrnr
      let sfeResponse = await this.fetcher.read("matrikel", "matrikel", "samletfastejendom", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      bfeNummer = sfeResponse.features[0].properties.BFEnummer
      if (bfeNummer)
        items.push(await this.getDetailItemForBfeNummer(bfeNummer, "Samlet fast ejendom"))
    } if (source === "Dawa" && typeId === "enhedsadresse") {
      let husnummerTilBfeResolver = new HusnummerAdresseTilBfeResolver({fetcher: this.fetcher, medtagadresser: this.medtagAdresser})
      let bfes = await husnummerTilBfeResolver.resolveAdresse(result.id)
      if (bfes.length > 0)
        items.push(await this.getEjlResultsItem(bfes))

    }
    return items
  }

  async getEjlResultsItem(bfeNumre) {
    let bfeResults = await this.searcher.gets(bfeNumre, "bfe")
    let detailItemsList = new DetailItemsList({
      header: bfeNumre.length == 1 ? "Ejerlejlighed": "Ejerlejligheder",
      name: "ejl_list"
    })
    for (let bfeResult of bfeResults) {
      detailItemsList.append({
        type: "result",
        result: bfeResult
      })
    }
    return detailItemsList.asItem()
  }

  async getDetailItemForBfeNummer(bfeNummer, header = "Ejendom") {
    let bfeResult = await this.searcher.get(bfeNummer, "bfe")
    let detailItemsList = new DetailItemsList({
      header,
      name: "bfe_info"
    })
    if (bfeResult) {
      detailItemsList.append({
        type: "result",
        result: bfeResult
      })
    } else {
      detailItemsList.append({
        type: "labelvalue",
        label: "bfe " + bfeNummer,
        value: "blev ikke fundet"
      })
    }
    return detailItemsList.asItem()
  }

}