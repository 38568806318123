import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import {ressources} from "../../util/Ressources.js"
import DetailItemsList from "../../details/DetailItemsList.js"

export default class OplysningerForBfe extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {String[]} [options.searcher]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Tingbog",
      targets: [{source: 'DAF', typeId: ['sfe', 'ejl', 'bfg', 'bfenummer']}]
    }
    super(Object.assign(defaultOptions, options))
    
    this.searcher = null
    this.fetcher = null
    if (options.searcher)
      this.searcher = options.searcher

    this.handlerFunction = this.myHandler

    this.ressources = {}
  }
  
  async myHandler(result) {
    let bfeNummer = result.id
    let ejendomSummarisk = await this.searcher.getTingbogForBfe(bfeNummer)

    let items = []
    items.push(this.getDocumentsInfo(ejendomSummarisk))
    items.push(await this.getHaeftelserResults(bfeNummer))
    items.push(await this.getServitutterResults(bfeNummer))

    if (ejendomSummarisk.AdkomsterSeptima && ejendomSummarisk.AdkomsterSeptima.length > 0) {
      for (let adkomstInfo of ejendomSummarisk.AdkomsterSeptima)
        items.push(... this.getAdkomstInfo(adkomstInfo))
    }
      
    return items
  }

  getDocumentsInfo(ejendomSummarisk) {
    let documentsDetailItemsList = new DetailItemsList({
      header: "Dokumenter"
    })
    let pdfUrl = ejendomSummarisk.Pdf
    let ressource = this.searcher.createRessource(pdfUrl)
    let ressourceId = ressources.putRessource(ressource)
    documentsDetailItemsList.append({
      ressourceId,
      linkTitle: "Tingbogsattest",
      type: "link"
    })
    let akter = ejendomSummarisk.Akter
    for (let akt of akter) {
      let aktUrl = akt.AktPdf
      let title = "Akt " + akt.AktId
      let ressource = this.searcher.createRessource(aktUrl)
      let ressourceId = ressources.putRessource(ressource)
      documentsDetailItemsList.append({
        ressourceId,
        linkTitle: title,
        type: "link"
      })
    }
    /*
    if (ejendomSummarisk.AdkomsterSeptima && ejendomSummarisk.AdkomsterSeptima.length > 0 && ejendomSummarisk.AdkomsterSeptima[0].DokumentPdf) {
      let adkomstInfo = ejendomSummarisk.AdkomsterSeptima[0]
    }
     */
    return documentsDetailItemsList.asItem()
  }

  async getServitutterResults(bfeNummer) {
    let servitutResultater = await this.searcher.getServitutterForBfe(bfeNummer)
    if (servitutResultater.length > 0) {
      let detailItemsList = new DetailItemsList({
        itemType: "result",
        header: "Servitutter",
        isHomogenous: true
      })
      for (let servitutResultat of servitutResultater) {
        detailItemsList.append({
          type: "result",
          result: servitutResultat
        })
      }
      return detailItemsList.asItem()
    } else {
      return this.getNoRecordsItemsList("Servitutter")
    }
  }

  async getHaeftelserResults(bfeNummer) {
    let haeftelsesResultater = await this.searcher.getHaeftelserForBfe(bfeNummer)
    if (haeftelsesResultater.length > 0) {
      let detailItemsList = new DetailItemsList({
        itemType: "result",
        header: "Hæftelser",
        isHomogenous: true
      })
      for (let haeftelsesResultat of haeftelsesResultater) {
        detailItemsList.append({
          type: "result",
          result: haeftelsesResultat
        })
      }
      return detailItemsList.asItem()
    } else {
      return this.getNoRecordsItemsList("Hæftelser")
    }
  }

  getAdkomstInfo(adkomstInfo) {
    let items = []
    let adkomstDetailsHeader
    let adkomstHaverDetailsHeader
    if (adkomstInfo.Dokumenttype == "skoede") {
      adkomstDetailsHeader = "Skøde"
      adkomstHaverDetailsHeader = "Adkomst"
    } else {
      adkomstDetailsHeader = "Adkomst"
      adkomstHaverDetailsHeader = "Adkomsthavere"
    } 
    //Skøde
    let adkomstDetailItemsList = new DetailItemsList({
      header: adkomstDetailsHeader,
      isHomogenous: true
    })
    if (adkomstInfo.AdkomstHandelKode) {
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "Type",
        value: adkomstInfo.AdkomstHandelKode
      })
    }

    if (adkomstInfo.DokumentPdf) {
      let pdfUrl = adkomstInfo.DokumentPdf
      let ressource = this.searcher.createRessource(pdfUrl)
      let ressourceId = ressources.putRessource(ressource)
      let linkTitel
      if (adkomstInfo.Dokumenttype == "skoede") {
        linkTitel = "Skøde"
      } else {
        linkTitel = "Tinglyst Adkomst"
      }

      adkomstDetailItemsList.append({
        ressourceId,
        linkTitle: linkTitel,
        type: "link"
      })
    }

    if (adkomstInfo.KontantKoebesum) {
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "Kontant købesum",
        value: adkomstInfo.KontantKoebesum
      })
    }
    if (adkomstInfo.KontantKoebesum) {
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "Købesum i alt",
        value: adkomstInfo.IAltKoebesum
      })
    }
    if (adkomstInfo.TinglysningsDato) {
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "Tinglyst",
        value: adkomstInfo.TinglysningsDato,
        valueformat: "iso-date"
      })
    }
    if (adkomstInfo.TinglysningAfgiftBetalt) {
      //TinglysningAfgiftBetalt
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "TinglysningAfgift",
        value: adkomstInfo.TinglysningAfgiftBetalt,
        valueformat: "currencyamount",
        valueunit: adkomstInfo.ValutaKode
      })
    }
    if (adkomstInfo.SkoedeOvertagelsesDato) {
      adkomstDetailItemsList.append({
        type: "labelvalue",
        label: "Overtagelse",
        value: adkomstInfo.SkoedeOvertagelsesDato,
        valueformat: "iso-date"
      })
    }
    items.push(adkomstDetailItemsList.asItem())

    //adkomsthaver
    if (adkomstInfo.adkomsthaver && adkomstInfo.adkomsthaver.length > 0) {
      let adkomstHaverdetailItemsList = new DetailItemsList({
        header: adkomstHaverDetailsHeader,
        isHomogenous: true
      })
      for (let adkomsthaver of adkomstInfo.adkomsthaver) {
        adkomstHaverdetailItemsList.append({
          type: "labelvalue",
          label: adkomsthaver.ejerandel,
          value: adkomsthaver.navn ? adkomsthaver.navn : "-"
        })
      }
      items.push(adkomstHaverdetailItemsList.asItem())
    }
    return items
  }
  
  getNoRecordsItemsList(header) {
    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "header",
      isHomogenous: true
    })
    detailItemsList.append({
      type: "labelvalue",
      value: "Ingen " + header.toLowerCase() + " registreret i Tingbogen"
    })
    return detailItemsList.asItem()
  }
}