import EsriJSON from 'ol/format/EsriJSON.js'
import GeoJSON from 'ol/format/GeoJSON.js'

function parse(esrijson) {
  const olGeometry = new EsriJSON().readGeometry(esrijson)
  const geojsonObject = new GeoJSON().writeGeometryObject(olGeometry)
  return geojsonObject
}

function write(geojsonObject) {
  const olGeometry = new GeoJSON().readGeometry(geojsonObject) 
  const esriGeometry = new EsriJSON().writeGeometryObject(olGeometry)
  const esriJSON = new EsriJSON().writeGeometry(olGeometry)
  let geometryType
  switch (geojsonObject.type.toLowerCase()) {
    case "point":
      geometryType = "esriGeometryPoint"
      break
    case "linestring":
    case "multilinestring":
      geometryType = "esriGeometryPolyline"
      break
    case "polygon":
    case "multipolygon":
      geometryType = "esriGeometryPolygon"
      break
    case "multipoint":
      geometryType = "esriGeometryMultipoint"
      break
    default:
      throw new Error("Cannot serialize geometry to esri format")
  }
  return {geometryType, esriJSON, esriGeometry}
}

export function getArcGisParser() {
  return {
    parse,
    write
  }
}
