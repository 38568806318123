/**
 * @module
 */
import DetailsHandlerDef from "./DetailsHandlerDef.js"
import Searcher from '../searchers/Searcher.js'
import jsonpath from "../util/JsonParser.js"
import DetailItemsList from "./DetailItemsList.js"
/**
 * Returnerer et resultat fra et andet resultat - Overvej at bruge Septima.Search.Details i stedet
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration</caption>
    _type: Septima.Search.DawaSearcher
    detailhandlers:
      - _type: Septima.Search.LinkedResultHandler
        _options:
          idExpression: $.properties.sogn.kode
          searcher:
            _ref: $.mysearchers.geosearcher
          typeId: sogne
          label: Sogn
 **/
export default class LinkedResultHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {string} [options.output=result] "result" | "labelvalue"
   * @param {string} [options.label]
   * @param {Object} options.searcher Searcher, som returnerer nye rersultat
   * @param {string} options.typeId typen af det nye resultat
   * @param {string} options.idExpression udtryk til at finde id på det nye resultat fra result.data
   **/
  constructor(options = {}) {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
      try{
        // eslint-disable-next-line no-console
        console.warn("LinkedResultHandler er deprecated. Brug Septima.Search.Details istedet (https://onedoor.test.septima.dk/guide/Septima.Search.Details.html)")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }

    let defaultOptions = {
      buttonText: options.label
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.searcher && result.searcher instanceof Searcher && jsonpath.value(result.data, this.idExpression))
    }
    this.mode = "result"

    if (options.output)
      this.mode = options.output

    this.label = null
    if (options.label)
      this.label = options.label

    if (options.searcher)
      this.searcher = options.searcher
    else
      throw 'LinkedResultHandler needs a searcher option'

    if (options.typeId) {
      if (this.searcher.hasType(options.typeId))
        this.typeId = options.typeId
      else
        throw "LinkedResultHandler needs a valid typeId option (options.searcher doesn't support " + options.typeId + " )"
    } else {
      throw 'LinkedResultHandler needs a typeId option'
    }

    if (options.idExpression)
      this.idExpression = options.idExpression
    else
      throw 'LinkedResultHandler needs an idExpression option'
  }

  async myHandler(result) {
    // eslint-disable-next-line no-useless-catch
    try {
      let item
      let detailItemsList
      let id = jsonpath.value(result.data, this.idExpression)
      if (id) {
        let result = await this.searcher.get(id, this.typeId)
        if (result) {

          if (this.mode == 'labelvalue') {
            item = {
              type: "labelvalue",
              value: result.title
            }
          } else {
            item = {
              type: "result",
              result: result
            }
          }
          if (this.label)
            item.label = this.label
          
          if (this.detailHandlerDefs.length > 0) {
            let listOptions = {
              name: this.id,
              itemType: "result",
              isHomogenous: true
            }
            if (this.label)
              listOptions.label = this.label
            
            detailItemsList = new DetailItemsList(listOptions)

            detailItemsList.append(item)
            return [detailItemsList.asItem()]
          }
          return [item]

        }
      }
      return []
    } catch (err) {
      throw err
    }
  }

}