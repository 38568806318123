/**
 * @module
 */
import BbrInfo from "../BbrInfo.js"
export default class BygningsInfoProvider extends BbrInfo {
  constructor(options) {
    super(options)
    try {
      // eslint-disable-next-line no-console
      console.warn("Septima.Search.Datafordeler.BygningsInfoProvider er deprecated. Brug Septima.Search.Datafordeler.BbrInfo i stedet")
      // eslint-disable-next-line no-empty
    }catch(e) {}
  }
}