import ClassRegistry from "../../ClassRegistry.js"
import OplysningerForBfe from "./OplysningerForBfe.js"
import Fetcher from "./Fetcher.js"
import Searcher from "./Searcher.js"
import InfoHandler from "./InfoHandler.js"


const tingbogTypes = new ClassRegistry("Septima.Search.Tingbog.")

const typesToAdd = {
  OplysningerForBfe,
  Fetcher,
  Searcher,
  InfoHandler
}

for (let [key, value] of Object.entries(typesToAdd))
  tingbogTypes.addClass(value, key)

export default tingbogTypes