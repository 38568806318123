import {JSONPath} from "jsonpath-plus"
import jsonpath  from './util/JsonParser.js'

export default class ClassRegistry  {
  constructor(prefix) {
    this.prefix = prefix ? prefix : ""
    this.registry = new Map()
    this.injectedRegistries = []
    this.types = null
    this.instances = {}
    this._logger
  }
  
  setTypes(types) {
    this.types = types
  }
  
  spawn(prefix) {
    let c = new ClassRegistry(prefix)
    c.parent = this
    return c
  }
  
  inject(otherRegistry) {
    this.injectedRegistries.push(otherRegistry)
  }
  
  set logger(logger) {
    if (logger) {
      this._logger = logger
      this._resolveLogger = this._logger.child({module: "classRegistry" + this.prefix})
    }
  }
  
  get logger() {
    if (this._logger)
      return this._logger
    if(typeof this.parent !== 'undefined')
      return this.parent.logger
    else
      return null
  }
  
  addClass(class2Add, className) {
    if (typeof className !== 'undefined')
      this.registry.set(className, class2Add)
    else
      throw new Error('ClassRegistry.takeClass called without className')
  }
  
  hasClass(longClassName) {
    //Do I, a parent or an injected classRegistry have the class. Yes is a guarantee that you may call getClass successfully

    //Does an injected registry have?
    for (let injectedRegistry of this.injectedRegistries)
      if (injectedRegistry.hasClass(longClassName))
        return true

    //Do I have
    if (longClassName.indexOf(this.prefix) === 0) {
      let shortName = longClassName.substring(this.prefix.length)
      if (this.registry.has(shortName))
        return true
    }
    
    //Does my parent have
    if (typeof this.parent !== 'undefined')
      return this.parent.hasClass(longClassName)
    return false
  }
  
  getClass(longClassName) {

    for (let injectedRegistry of this.injectedRegistries) {
      let c = injectedRegistry.getClass(longClassName)
      if (c)
        return c
    }

    if (longClassName.indexOf(this.prefix) === 0) {
      let shortName = longClassName.substring(this.prefix.length)
      if (this.registry.has(shortName))
        return this.registry.get(shortName)
    }
    
    if (typeof this.parent !== 'undefined') {
      let c = this.parent.getClass(longClassName)
      if (c)
        return c
    }
  }
  
  forClass(longClassName, options) {
    for (let injectedRegistry of this.injectedRegistries)
      if (injectedRegistry.hasClass(longClassName)) {
        let c = injectedRegistry.getClass(longClassName)
        return this.create(longClassName, c, options)
      }
    if (this.hasClass(longClassName)) {
      let c = this.getClass(longClassName)
      return this.create(longClassName, c, options)
    } 
    throw new Error('Class not found. Class ' + longClassName + ' is not defined')
  }

  create (longClassName, c, options = {}) {
    
    try {
      const start = Date.now()

      //Create an instance with a proper id as option
      if (typeof this.instances[longClassName] === 'undefined')
        this.instances[longClassName] = []

      let classCount = this.instances[longClassName].length

      if (typeof options.id === 'undefined')
        options.id = longClassName + "_" + classCount

      if (this.logger)
        options.logger = this.logger

      let instance = new c(options)

      this.instances[longClassName].push(instance)
      if (this._resolveLogger && 1==2) {
        const duration = Date.now() - start
        let logObject = {}
        logObject.method = 'create'
        logObject.class = longClassName
        logObject.duration = duration
        this._resolveLogger.debug(logObject)
      }

      return instance
    }catch(error) {
      throw new Error("ClassRegistry.create: Could not create object of Class " + longClassName, error)
    }
    
  }


  getClasses() {
    let classesMap = this._getClasses()
    let classes = []
    classesMap.forEach(function(info, className) {
      info.className = className
      classes.push(info)
    })
    return classes
  }
  
  _getClasses() {
    let classes = new Map()
    if (typeof this.parent !== 'undefined')
      this.parent._getClasses().forEach((info, className)=>classes.set(className, info))
    
    this.registry.forEach((type, className)=> {
      let info = {prefix : this.prefix}
      if ((this.types && this.types[className])) {
        info = this.types[className]
        info.prefix = this.prefix
      }
      classes.set(className, info)
    })
    
    for (let injectedRegistry of this.injectedRegistries)
      injectedRegistry._getClasses().forEach((info, className)=>classes.set(className, info))
    
    return classes
  }
  
  merge(referrer, referee) {
    let newObject = {}
    // referee _ref / _type wins
    if (referee['_ref']) {
      newObject['_ref'] = referee['_ref']
    } else {
      if (referee['_type']) {
        newObject['_type'] = referee['_type']
      } else {
        return referee
      }
    }

    if (referrer['_options'] || referee['_options']) {
      //Options are merged
      let referrerOptions = referrer['_options'] || {}
      let refereeOptions = referee['_options'] || {}
      newObject['_options'] = Object.assign({}, refereeOptions, referrerOptions)
    }

    //Other properties are merged
    Object.keys(referrer).forEach(propertyName => {
      if (propertyName !== '_type' && propertyName !== '_ref' && propertyName !== '_options')
        newObject[propertyName] = referrer[propertyName]
    })
    Object.keys(referee).forEach(propertyName => {
      if (propertyName !== '_type' && propertyName !== '_ref' && propertyName !== '_options')
        newObject[propertyName] = referee[propertyName]
    })

    return newObject
  }
  
  refsToBeFixed(cleanObject) {
    let refsToBeFixed = []
    let refNodes = jsonpath.nodes(cleanObject, '$..[?(@ && @._ref)]')
    for (let refNode of refNodes) {
      let refersTo = jsonpath.value(cleanObject, refNode.value['_ref'])
      if (typeof refersTo === 'undefined' || refersTo === null) {
        throw new Error("refnode at " + refNode.path + " with value " + refNode.value['_ref'] + " doesn't point to anything")
      }else if (refersTo['_ref']) {
        //refersTo is a _ref
        if ( Object.keys(refersTo).length == 1 || Object.keys(refNode.value).length > 1)
          refsToBeFixed.push(refNode)
      } else if (refersTo['_type']) {
        //refersTo is _type - do nothing
        if (Object.keys(refNode.value).length > 1)
          refsToBeFixed.push(refNode)
      } else {
        // refersTo is a normal object
        refsToBeFixed.push(refNode)
      }
    }
    return refsToBeFixed
  }
  
  fixRefs(cleanObject) {
    //When this is finished only ref's to types should exist
    let refNodes = this.refsToBeFixed(cleanObject)
    let refNodesCount = refNodes.length
    
    while (refNodesCount > 0) {
      for (let refNode of refNodes) {
        let refersTo = jsonpath.value(cleanObject, refNode.value['_ref'])
        if (typeof refersTo === 'undefined' || refersTo === null)
          throw new Error("refnode at " + refNode.path + " with value " + refNode.value['_ref'] + " doesn't point to anything")
        else if (refersTo._ref) {
          //refersTo is a _ref
          let refNodeNewValue = this.merge(refNode.value, refersTo)
          jsonpath.value(cleanObject, refNode.path, refNodeNewValue)
        } else  if (refersTo._type) {
          //refersTo is _type
          let refNodeNewValue = this.merge(refNode.value, refersTo)
          jsonpath.value(cleanObject, refNode.path, refNodeNewValue)
        } else {
          // refersTo is a normal object
          jsonpath.value(cleanObject, refNode.path, refersTo)
        }
      }
      refNodes = this.refsToBeFixed(cleanObject)
      if (refNodes.length < refNodesCount)
        refNodesCount = refNodes.length
      else
        throw new Error("fixRefs: couldn't reduce number of refNodes")
    }
    return cleanObject
  }
  
  
  substitueRefnodesForReferred(cleanObject, reffedTypes) {
    let refNodes = jsonpath.nodes(cleanObject, '$..[?(@ && @._ref)]')
    //let refsPointingToRef = []
    for (let refNode of refNodes) {
      let refersTo = jsonpath.value(cleanObject, refNode.value['_ref'])
      if (typeof refersTo === 'undefined' || refersTo === null)
        throw new Error("refnode at " + refNode.path + " with value " + refNode.value['_ref'] + " doesn't point to anything")
      else if (refersTo._ref) {
        //refersTo is a _ref - should never happen
        throw ("bitch")
      } else  if (refersTo._type) {
        //refersTo is _type
        if (Object.keys(refNode.value).length > 1) {
          throw ("bitch")
          //let refNodeNewValue = this.merge(refNode.value, refersTo)
          //jsonpath.value(cleanObject, refNode.path, refNodeNewValue)
        } else {
          reffedTypes.push({referringNode: refNode, refersToPath: jsonpath.normalize(refNode.value['_ref'])})
        }
      } else {
        // refersTo is a normal object - should never happen
        throw ("bitch")
        //jsonpath.value(cleanObject, refNode.path, refersTo)
      }
    }
  }
  
  parse(object) {
    const start = Date.now()
    let typeNodes = [] // of jsonpath.node
    let subTypes = [] // {parent: jsonpath.node, child: jsonpath.node, childPath}
    let subRefs = [] // {parent: jsonpath.node, child: jsonpath.node}
    let reffedTypes = [] // {referringNode: jsonpath.node, refersToPath: path}
    //Object may contain "_type", and "_ref" tags
    //let cleanObject = JSON.parse(JSON.stringify(object))
    let cleanObject = object
    cleanObject = this.fixRefs(cleanObject)
    this.substitueRefnodesForReferred(cleanObject, reffedTypes) 

    typeNodes = jsonpath.nodes(cleanObject, '$..[?(@ && @._type)]')
    for (let typeNode of typeNodes) {
      let subRefNodes = jsonpath.nodes(typeNode.value, '$..[?(@ && @._ref)]')
      for (let subRefNode of subRefNodes)
        subRefs.push({parent: typeNode, child: subRefNode})
      let subTypeNodes = jsonpath.nodes(typeNode.value, '$..[?(@ && @._type)]')
      for (let subTypeNode of subTypeNodes) {
        let childPath = [...JSONPath.toPathArray(typeNode.path), ...JSONPath.toPathArray(subTypeNode.path).slice(1)]
        childPath = JSONPath.toPathString(childPath)
        subTypes.push({parent: typeNode, child: subTypeNode, childPath: childPath})
      }
    }
    if (this._resolveLogger) {
      const duration = Date.now() - start
      let logObject = {}
      logObject.method = 'parse'
      logObject.duration = duration
      this._resolveLogger.debug(logObject, 'parsed')
    }

    return {object2Return: cleanObject, typeNodes, subTypes, subRefs, reffedTypes}
  }
  
  get classes() {
    let parentClasses = this.parent ? this.parent.classes : {}
    return Object.assign({}, parentClasses, this.registry)
  }
  
  resolve(object) {
    //Hack since jsonpath cant find first level children
    const start = Date.now()
    let parseResult = this.parse(object)
    let typenodeInstances = []
    while (parseResult.typeNodes.length > 0) { 
      let typeNodeCount = parseResult.typeNodes.length
      for (let i = parseResult.typeNodes.length - 1; i >= 0; i--) {
        let typeNode = parseResult.typeNodes[i]
        let optionsChildren = this.optionChildren(typeNode, parseResult)
        if (optionsChildren.length === 0) {
          let instance = this.instantiate(typeNode, parseResult)
          //Remove from typeNodes
          parseResult.typeNodes.splice(i, 1)
          let children = this.children(typeNode, parseResult)
          if (children.length === 0)
            this.insertType(typeNode, instance, parseResult)
          else
            //Insert into collection for later insert
            typenodeInstances.push({typeNode, instance})
        }
      }
      if (parseResult.typeNodes.length === typeNodeCount) {
        // No nodes have been resolved in this pass -> Throw Error
        let unresolvablePaths = parseResult.typeNodes.map((typeNode)=> {
          return{"path": typeNode.path, "children": this.children(typeNode, parseResult)} 
        } )
        throw new Error("Can't resolve: Unresolvable nodes: " + JSON.stringify(unresolvablePaths, null, 2))
      }
    }
    //TBD Eliminate refs to non-types
    while (typenodeInstances.length > 0) {
      let typenodeInstanceCount = typenodeInstances.length
      for (let i = typenodeInstances.length - 1; i >= 0; i--) {
        let {typeNode, instance} = typenodeInstances[i]
        let children = this.children(typeNode, parseResult)
        if (children.length === 0) {
          this.insertType(typeNode, instance, parseResult)
          typenodeInstances.splice(i, 1)
        }
      }
      if (typenodeInstances.length === typenodeInstanceCount) {
        // No nodes have been resolved in this pass -> Throw Error
        let unresolvablePaths = typenodeInstances.map((typenodeInstance)=> {
          return{
            "path": typenodeInstance.typeNode.path,
            "value": typenodeInstance.typeNode.value,
            "children": this.children(typenodeInstance.typeNode, parseResult)
          }
        } )
        throw new Error("Can't insert: Uninsertable nodes: " + JSON.stringify(unresolvablePaths, null, 2))
      }
    }
    //We got to here - this means all typenodes have been converted to typenodeInstances
    if (this._resolveLogger) {
      const duration = Date.now() - start
      let logObject = {}
      logObject.method = 'resolve'
      logObject.duration = duration
      this._resolveLogger.debug(logObject, 'resolved')
    }

    return parseResult.object2Return
  }

  children(typeNode, parseResult) {
    let children = []
    let pathToCheck = typeNode.path
    for (let subType of parseResult.subTypes)
      if (subType.parent.path === pathToCheck)
        children.push(subType.child.path)
    for (let subRef of parseResult.subRefs)
      if (subRef.parent.path === pathToCheck)
        children.push(subRef.child.path)
    return children
  }
  
  optionChildren(typeNode, parseResult) {
    let children = []
    let pathToCheck = typeNode.path
    for (let subType of parseResult.subTypes)
      if (subType.parent.path === pathToCheck && JSONPath.toPathArray(subType.child.path)[1] === '_options')
        children.push(subType.child.path)
    for (let subRef of parseResult.subRefs)
      if (subRef.parent.path === pathToCheck && JSONPath.toPathArray(subRef.child.path)[1] === '_options')
        children.push(subRef.child.path)
    return children
  }

  insertType(typeNode, instance, parseResult) {
    this.setInstanceProperties(instance, typeNode.value)
    // set the type member to the instance
    let typeNodePath = typeNode.path
    jsonpath.value(parseResult.object2Return, typeNodePath, instance)
    // Remove the subType info
    parseResult.subTypes = parseResult.subTypes.filter(subType => subType.childPath !== typeNodePath)
  }
  
  instantiate(typeNode, parseResult) {
    let typeNodePath = typeNode.path

    //Create an instance
    let instance = this.forClass(typeNode.value['_type'], typeNode.value._options)

    for (var i = parseResult.reffedTypes.length - 1; i >= 0; i--) {
      let reffedType = parseResult.reffedTypes[i]
      //{referringNode: jsonpath.node, refersToPath: path}
      if (reffedType.refersToPath === typeNodePath) {
        //The reffedType is the typeNode
        let referringPath = reffedType.referringNode.path
        // set the _ref member to the instance
        jsonpath.value(parseResult.object2Return, referringPath, instance)
        // Remove the reffed info
        parseResult.reffedTypes.splice(i, 1)
        //parseResult.subRefs = parseResult.subRefs.filter(subRef => jsonpath.stringify(subRef.child.path) !== referringPath)
        parseResult.subRefs = parseResult.subRefs.filter(subRef => jsonpath.normalize(subRef.child.value["_ref"]) !== typeNodePath)
      }
    }
    return instance
  }
  
  setInstanceProperties(instance, typeNodeValue) {
    Object.keys(typeNodeValue).forEach(propertyName => {
      if (propertyName !== '_type' && propertyName !== '_options')
        instance[propertyName] = typeNodeValue[propertyName]
    })
  }

}
