import ArcGisSearcher from "./searchers/ArcGisSearcher.js"
import ArcGisFeatureInfoProvider from "./searchers/detailhandlers/ArcGisFeatureInfoProvider.js"
import DataSearcher from "./searchers/DataSearcher.js"
import S4IndexSearcher from "./searchers/S4IndexSearcher.js"
import S3Searcher from "./searchers/S3Searcher.js"
import SqSearcher from "./searchers/SqSearcher.js"
import SearchableData from "./searchers/local/SearchableData.js"
import SearchableGeoJson from "./searchers/local/SearchableGeoJson.js"
import SqDetailsHandler from "./details/SqDetailsHandler.js"
import ComposedDetailsHandler from "./details/ComposedDetailsHandler.js"
import Details from "./details/Details.js"
import S4InfoHandler from "./searchers/detailhandlers/S4InfoHandler.js"
import DemoDetailsHandler from "./searchers/detailhandlers/DemoDetailsHandler.js"
import DummyMoreHandler from "./searchers/detailhandlers/DummyMoreHandler.js"
import Controller from "./Controller.js"
import ClassRegistry from "./ClassRegistry.js"
import ClientSearcher from "./searchers/ClientSearcher.js"
import ThrowingDetailsProvider from "./searchers/detailhandlers/ThrowingDetailsProvider.js"
import GeometrySearchProvider from "./searchers/providers/GeometrySearchProvider.js"
import StaticDetailsProvider from "./searchers/detailhandlers/StaticDetailsProvider.js"
import S4NearestHandler from "./searchers/detailhandlers/S4NearestHandler.js"
import CookieResultStore from "./defaultview/resultstore/CookieResultStore.js"
import HistorySearcher from "./searchers/HistorySearcher.js"
import ResultType from "./ResultType.js"
import MemoryResultStore from "./searchers/local/MemoryResultStore.js"
import ResultStore from "./searchers/local/ResultStore.js"
import StaticResultStore from "./searchers/local/StaticResultStore.js"
import ResultStoreSearcher from "./searchers/ResultStoreSearcher.js"
import LocalstorageResultStore from "./defaultview/resultstore/LocalstorageResultStore.js"
import LinkedResultHandler from "./details/LinkedResultHandler.js"
import GenericWfsSearcher from "./searchers/GenericWfsSearcher.js"
import Searcher from "./searchers/Searcher.js"
import DetailsHandlerDef from "./details/DetailsHandlerDef.js"
const coreTypes = new ClassRegistry("Septima.Search.")

const typesToAdd = {
  Searcher,
  DetailsHandlerDef,
  ArcGisSearcher,
  ArcGisFeatureInfoProvider,
  DataSearcher,
  ClientSearcher,
  S4IndexSearcher,
  S4InfoHandler,
  S4NearestHandler,
  S3Searcher,
  SqSearcher,
  SearchableData,
  SearchableGeoJson,
  GenericWfsSearcher,
  SqDetailsHandler,
  ComposedDetailsHandler,
  DemoDetailsHandler,
  ThrowingDetailsProvider,
  DummyMoreHandler,
  Controller,
  GeometrySearchProvider,
  StaticDetailsProvider,
  ResultStore,
  MemoryResultStore,
  CookieResultStore,
  LocalstorageResultStore,
  StaticResultStore,
  ResultStoreSearcher,
  HistorySearcher,
  ResultType,
  LinkedResultHandler,
  Details
}

for (let [key, value] of Object.entries(typesToAdd))
  coreTypes.addClass(value, key)

export default coreTypes