import {fetch2} from "../../utils.js"

export default class Fetcher {
  /**
   * @param {Object} options
   * @param {string} [options.token] token til PostGrest
   * @param {string} [options.endpoint=https://redata-postgrest.septima.dk]
   **/
  constructor(options = {}) {
    this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoicHJvZF9yZWRhdGFfcmVhZCIsImV4cCI6MjI3ODEwMjc3MH0.o6wwzNNMk799XRho1xhODw35eDYkJcAjf61-RJzVkCo"
    if (options.token)
      this.token = options.token
    
    this.endpoint = "https://redata-postgrest.septima.dk"
    if (options.endpoint)
      this.endpoint = options.endpoint
  }

  async search(target, searchText, maxRows, params= {}) {
    let url = `${this.endpoint}/rpc/${target}`
    let finalQuery = (searchText.trim() == "") ? "a" : searchText
    if (params.geometry)
      finalQuery = ""

    let rpcParms = Object.assign( {
      searchtext: finalQuery,
      max_rows_returned: Math.min(maxRows, 199)
    },
    params)
    
    let fetchOptions = {data: rpcParms}
    if (this.token)
      fetchOptions.Authorization =  {Bearer: {token: this.token}}
    return await fetch2(url, fetchOptions)
  }

  async get(target, params) {
    let url = `${this.endpoint}/${target}`
    let fetchOptions = {data: params}
    if (this.token)
      fetchOptions.Authorization =  {Bearer: {token: this.token}}
    return await fetch2(url, fetchOptions)
  }

  async rpc(rp, params, logger) {
    let url = `${this.endpoint}/rpc/${rp}`
    let fetchOptions = {
      method: "post",
      contentType:'application/json',
      data: JSON.stringify(params),
      throwOnHttpError: true
    }
    if (this.token)
      fetchOptions.Authorization =  {Bearer: {token: this.token}}
    return await fetch2(url, fetchOptions, logger)
  }

  //https://redata-postgrest.septima.dk/rpc/jordstykke_geom_agg

}
