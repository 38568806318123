/**
 * @module
 */
import {  fetch2  } from "../../utils.js"

/**
 * Forbinder til septima data-api / PostGrest
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.DataApi.Fetcher
 *   _options:
 *     token: tttttttttttttt
 *     er_aktiv:
 *       - false
 *       - true
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 var fetcher = new Septima.Search.DataApi.Fetcher({token: "......"})
 * @api
 **/
export default class Fetcher {
  /**
   * @param {Object} options
   * @param {string} options.token token til data-api
   * @param {Array} options.er_aktiv  skal medtage aktive ell er inaktive eller begge
   * @param {string} [options.endpoint=https://data-api.septima.dk]
   **/
  constructor(options = {}) {
    if (options.test) {
      this.token = options.token ?? "jcI0o7C9O26utwCv"
      this.endpoint = options.endpoint ?? "https://data-api.test.septima.dk"
    } else {
      this.token = options.token ?? "m1FkNUEytCt3xh6X"
      this.endpoint = options.endpoint ?? "https://data-api.septima.dk"
    }

    this.service = this.endpoint + "/postgrest"
  }

  async search(searchRp, searchText, maxRows, params  = {}, logger) {
    let finalQuery = searchText.trim()
    if (params.geometry)
      finalQuery = ""

    let postData = {
      searchtext: finalQuery,
      max_rows_returned: maxRows,
      filter: {}
    }

    if (params.er_aktiv)
      postData.filter['er_aktiv'] = params.er_aktiv
    if (params.kommunekoder)
      postData.filter['kommuner'] = params.kommunekoder
    if (params.kommuner)
      postData.filter['kommuner'] = params.kommuner
    if (params.hovedafdelingkode)
      postData.filter['branche_hovedafdelinger'] = params.hovedafdelingkode
    if (params.hovedgruppekode)
      postData.filter['branche_hovedgrupper'] = params.hovedgruppekode
    if (params.gruppekode)
      postData.filter['branche_grupper'] = params.gruppekode
    if (params.undergruppekode)
      postData.filter['branche_undergrupper'] = params.undergruppekode
    if (params.branchekode)
      postData.filter['branchekoder'] = params.branchekode
    if (params.geometri)
      postData.filter['geometri'] = params.geometri

    let url = `${this.service}/rpc/${searchRp}?token=${this.token}`

    let options = {
      method: "post",
      contentType:'application/json',
      data: JSON.stringify(postData),
      throwOnHttpError: true
    }
    try {
      //return await this.rpc(searchRp, postData, logger)
      return await fetch2(url, options, logger)
    } catch (e) {
      return null
    }
  }

  async rpc(rp, params, logger) {
    let url = `${this.service}/rpc/${rp}?token=${this.token}`
    let options = {
      method: "post",
      contentType:'application/json',
      data: JSON.stringify(params),
      throwOnHttpError: true
    }
    return await fetch2(url, options, logger)
  }
  
  async get(target, params, logger) {
    let url = `${this.service}/${target}?token=${this.token}`
    return await fetch2(url, {  data: params  }, logger)
  }

}
