/**
 * @module
 */
import DetailsHandlerDef from "@septima/septima-search/details/DetailsHandlerDef.js"
import GeoSearch from "@septima/septima-search/searchers/geosearch/GeoSearch.js"

export default class EjdExplorerLinks extends DetailsHandlerDef {

  constructor(options) {
    let defaultOptions = {
      buttonText: "Ejdexplorer"
    }
    super(Object.assign(defaultOptions, options))
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler
    this.geosearcher = new GeoSearch({ "targets": ['matrikelnumre'] })

  }

  isApplicable(result) {
    return (result.typeId === "Geometry"  || result.typeId === "matrikelnumre" ||  result.typeId === "adresse" )
  }

  async handler(result) {


    let detailItems = []
    if (result.typeId === "Geometry") {
      let mydata = await this.geosearcher.sq({ "geometry": result.geometry })
      let elavskoder = []
      let matrikelnumre = []
      
      for (const i of mydata._results) {
  
        elavskoder.push(i.data.elavskode)
        matrikelnumre.push(i.data.matrnr)
      }
      detailItems.push({
        type: "link",
        linkTitle: "Se matrikelnumre Ejdexplorer",
        link: `ejdexpl://?mode=merge&CadastralDistrictIdentifier=${elavskoder}&RealPropertyKey=${matrikelnumre}`
      })
    }
    if (result.typeId === "matrikelnumre") {
      detailItems.push({
        type: "link",
        linkTitle: "Se matrikelnummer Ejdexplorer",
        link: `ejdexpl://?mode=merge&CadastralDistrictIdentifier=${result.data.elavskode}&RealPropertyKey=${result.data.matrnr}`
      })
    }
    if (result.typeId === "adresse") {
      detailItems.push({
        type: "link",
        linkTitle: "Se adresse Ejdexplorer",
        link: `ejdexpl://?mode=merge&municipalitycode=${result.data.properties.kommune.kode}&streetcode=${result.data.properties.vejstykke.kode}&housecode=${result.data.properties.husnr}`
      })
      
    }
    return detailItems
  }



}


