import OplysningerForBfe from "./OplysningerForBfe.js"
import Fetcher from "./Fetcher.js"
import Searcher from "./Searcher.js"
import InfoHandler from "./InfoHandler.js"

export default {
  OplysningerForBfe,
  Fetcher,
  Searcher,
  InfoHandler
}